var render = function render(){
  var _vm$work, _vm$work2, _vm$work3, _vm$work3$author, _vm$work3$author$name, _vm$work4, _vm$work5, _vm$work6, _vm$work7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container"
  }, [_c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(0), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('bids-item-imgs', {
    attrs: {
      "imgs": ((_vm$work = _vm.work) === null || _vm$work === void 0 ? void 0 : _vm$work.imgs) || []
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "py-10 py-md-14"
  }, [_c('div', [_c('strong', {
    staticClass: "font-size-14 font-weight-medium"
  }, [_vm._v("LOT. " + _vm._s((_vm$work2 = _vm.work) === null || _vm$work2 === void 0 ? void 0 : _vm$work2.lot))])]), _c('div', {
    staticClass: "mt-8 mt-md-10 ellip"
  }, [_c('strong', {
    staticClass: "font-size-20 font-size-md-24 font-weight-medium"
  }, [_vm._v(_vm._s((_vm$work3 = _vm.work) === null || _vm$work3 === void 0 ? void 0 : (_vm$work3$author = _vm$work3.author) === null || _vm$work3$author === void 0 ? void 0 : (_vm$work3$author$name = _vm$work3$author.name) === null || _vm$work3$author$name === void 0 ? void 0 : _vm$work3$author$name.ko))])]), _c('div', {
    staticClass: "mt-md-2 ellip"
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18"
  }, [_vm._v(_vm._s((_vm$work4 = _vm.work) === null || _vm$work4 === void 0 ? void 0 : _vm$work4.subject))])]), _c('div', {
    staticClass: "mt-10 mt-md-12"
  }, [_c('span', {
    staticClass: "font-size-14 grey-68--text"
  }, [_vm._v(_vm._s((_vm$work5 = _vm.work) === null || _vm$work5 === void 0 ? void 0 : _vm$work5.year) + " ㅣ " + _vm._s((_vm$work6 = _vm.work) === null || _vm$work6 === void 0 ? void 0 : _vm$work6.material) + " ㅣ " + _vm._s((_vm$work7 = _vm.work) === null || _vm$work7 === void 0 ? void 0 : _vm$work7.dimensionTag))])])])])], 1)], 1)]), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(1), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "이름"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.meta.name) + " ")]), _c('myinfo-modify-row', {
    attrs: {
      "label": "연락처"
    }
  }, [_c('div', {
    staticClass: "mw-lg-500px"
  }, [_vm._v(" " + _vm._s(_vm.form.meta.phone) + " ")])]), _c('myinfo-modify-row', {
    attrs: {
      "label": "이메일"
    }
  }, [_c('div', {
    staticClass: "mw-lg-500px"
  }, [_vm._v(" " + _vm._s(_vm.form.meta.email) + " ")])])], 1)])]), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(2), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "제목"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "내용"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "grey-cb"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, [_vm._v("문의하기")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("문의 작품")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("문의자 정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("문의 상세")])]);

}]

export { render, staticRenderFns }